.images {
  padding: 15px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: rgb(255, 255, 255);
  position:fixed;
  left:0;
  top:0;
  z-index:9999;
}
.ant-layout-header{
  padding: 16px;
}

.cemeteryTable{
  margin-top: 64px;
}