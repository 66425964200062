.imageContainer {
  display: flex;
  justify-content: center;
}
.image {
  align-self: center;
}

.propertyTable{
  margin-top: 64px;
}

.ant-collapse-header{
  font-size: 24px;
  font-weight: 600;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: rgb(255, 255, 255);
  position:fixed;
  left:0;
  top:0;
  z-index:9999;
}
.ant-layout-header{
  padding: 16px;
}

.ant-collapse {
  background-color: white;
  border: 0px;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse>.ant-collapse-item{
  border-bottom: 0px;
}

.ant-table-cell,.ant-table-footer{
  font-size: 16px;
}