@media screen and (min-width:1000px) {
  .formContainer {
    background-color: #f1f1f1;
    padding: 16px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 999px) {
  .formContainer {
    background-color: #f1f1f1;
    padding: 16px;
    font-size: 16px;
    display: block;
    flex-direction: row;
  }
}

.subTitle {
  padding-inline: 15px;
}

.searchBox {
  flex: 1;
  min-width: 140px;
}

.rowClass {
  cursor: pointer;
}

.link-valuation-number {
  color: #0000ee;
  text-decoration: underline;
}

.link-valuation-number:hover {
  opacity: 0.6;
}

.propertySubmitButton {
  background-color: #5bc500;
  color: white;
  flex: 1;
  min-width: 100%;
}
@media (max-width: 999px) {
  .propertySubmitButton {
    background-color: #5bc500;
    color: white;
    flex: 1;
    min-width: 100%;
    margin-top: 25px;
  }
}
.propertySubmitButton:focus,
.propertySubmitButton:hover {
  color: #5bc500;
  border-color: #5bc500;
  background: #fff;
}
